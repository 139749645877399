import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: '/'
  },
  {
    id: 'content',
    title: 'Content Editor',
    type: 'section',
    icon: 'image',
    children: [
      {
        id: 'home',
        title: 'Home Page',
        type: 'item',
        icon: 'home',
        url: 'content/home'
      },
      {
        id: 'contact-page',
        title: 'Contact Page',
        type: 'item',
        icon: 'codepen',
        url: 'content/contact-page'
      },{
        id: 'solutions-page',
        title: 'Solutions Page',
        type: 'item',
        icon: 'layers',
        url: 'content/solutions-page'
      },
      {
        id: 'about-page',
        title: 'About Page',
        type: 'item',
        icon: 'command',
        url: 'content/about'
      },
      {
        id: 'about-page',
        title: 'Careers',
        type: 'item',
        icon: 'command',
        url: 'content/career-page'
      },
      {
        id: 'slider',
        title: 'Slider',
        type: 'item',
        icon: 'columns',
        url: 'slider'
      },
      {
        id: 'about',
        title: 'Customers',
        type: 'item',
        icon: 'command',
        url: 'customer'
      },
      // {
      //   id: 'service',
      //   title: 'Service Page',
      //   type: 'item',
      //   icon: 'check-circle',
      //   url: 'content/service'
      // },
      {
        id: 'contact',
        title: 'Contact Us Form',
        type: 'item',
        icon: 'codepen',
        url: 'content/contact'
      },
      {
        id: 'newsletter',
        title: 'Newsletter',
        type: 'item',
        icon: 'voicemail',
        url: 'content/newsletter'
      },
      {
        id: 'config',
        title: 'Social Media',
        type: 'item',
        icon: 'cpu',
        url: 'content/config'
      }
    ]
  },
]
